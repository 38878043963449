<script lang="ts" setup>
  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  const data = props.customData ?? { keyTakeaways: '' } as { keyTakeaways: string };
</script>

<template>
  <div v-if="data.keyTakeaways !== ''" class="key-takeaways vid-space">
    <h5>What You'll Learn</h5>
    <ul class="list" v-html="data.keyTakeaways"></ul>
  </div>
</template>