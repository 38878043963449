import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = {
  id: "video-landing-page-form-wrapper",
  class: "vid-form vid-space"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-12 col-md-6 col-xl-5" }
const _hoisted_7 = { class: "col-12 col-md-6 col-xl-6 offset-xl-1" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["resource-landing-page resource-video eclipse-version", [$setup.ungatedClass, { playing: $setup.videoPlaying }]])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode($setup["topContent"], { "custom-data": $setup.data }, {
            breadcrumb: _withCtx(() => [
              _createVNode($setup["breadCrumbs"], { "custom-data": $setup.data }, null, 8 /* PROPS */, ["custom-data"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["custom-data"])
        ]),
        _createCommentVNode(" The Main Video Content "),
        _createVNode($setup["videoContent"], {
          "custom-data": $setup.data,
          "widget-data": $props.widgetData
        }, {
          "no-gate-video": _withCtx(() => [
            _createVNode($setup["noGateVideo"], {
              onPlayclicked: _cache[0] || (_cache[0] = $event => $setup.btnClick = $event)
            })
          ]),
          "resource-form": _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode($setup["resourceForm"], {
                "form-settings-key": $props.formSettingsKey,
                "widget-data": $props.widgetData,
                "disable-form-heading": true,
                "custom-data": $props.customData
              }, null, 8 /* PROPS */, ["form-settings-key", "widget-data", "custom-data"])
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["custom-data", "widget-data"]),
        _createElementVNode("div", _hoisted_5, [
          _createCommentVNode(" About this Doc "),
          _createElementVNode("div", _hoisted_6, [
            (!$setup.showUnlockedView)
              ? (_openBlock(), _createBlock($setup["aboutVid"], {
                  key: 0,
                  "custom-data": $setup.data
                }, null, 8 /* PROPS */, ["custom-data"]))
              : _createCommentVNode("v-if", true)
          ]),
          _createCommentVNode(" Key Takeaways "),
          _createElementVNode("div", _hoisted_7, [
            (!$setup.showUnlockedView)
              ? (_openBlock(), _createBlock($setup["keyTakeaways"], {
                  key: 0,
                  "custom-data": $setup.data
                }, null, 8 /* PROPS */, ["custom-data"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ])
  ], 2 /* CLASS */))
}