<script setup lang="ts">
  import type { VideoFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import { type FormWidgetData } from "../../../widgetDataTs.js";
  import { type Nil, isNil } from "@mcwd/typescript-type-guards";
  import { ref, computed, watch, onBeforeUpdate } from "vue";
  import { useBlindSubmit, useFormServices } from "../../composables/use-form-services.js";
  import topContent from "./resourceLibrary/top-content.vue";
  import breadCrumbs from './bread-crumbs.vue';
  import videoContent from './resourceLibrary/video-content.vue';
  import noGateVideo from './resourceLibrary/no-gate-video.vue';
  import resourceForm from "../form-element/resource-form-base.vue";
  import aboutVid from './resourceLibrary/about-vid.vue';
  import keyTakeaways from './resourceLibrary/key-takeaways.vue';
  import { useFormLogger } from "../../composables/use-form-logger.js";

  const props = defineProps<{
    formSettingsKey: VideoFormSettingsKey;
    widgetData: FormWidgetData<"GatedVideoForm">;
    customData?: Record<string, any>;
  }>();

  const { logger } = useFormLogger();

  logger.timeEnd(`${props.formSettingsKey}-experienceLoad`);
  logger.time(`${props.formSettingsKey}-experienceRender`);

  type LandingPageData = {
    assetType: string,
    heading: string,
    thumbnail: string,
    subHeading: string,
    summary: string,
    breadCrumbList: string,
    keyTakeaways: string,
  }

  function isLandingPageData(value: Record<string, any> | Nil): value is LandingPageData {
    return !isNil(value)
      && ("assetType" in value)
      && ("heading" in value)
      && ("thumbnail" in value)
      && ("subHeading" in value)
      && ("summary" in value)
      && ("breadCrumbList" in value)
      && ("keyTakeaways" in value);
  }
  const data = computed<LandingPageData>(() => {
    if (isLandingPageData(props.customData)) {
      return props.customData;
    } else {
      throw new Error('CustomData is not of type LandingPageData!');
    }
  });

  // Removes the utm-url class if form success
  const afterCompleteHandler = () => {
    $('.utm-url').removeClass('utm-url');
  };

  const videoData = computed(() => {
    return props.widgetData.setup.form.video.selected;
  });

  const { FormState, CurrentFormExperienceGatingState } = useFormServices({ callerLabel: "videoForm" });


  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete" || FormState.value === "BlindForm" || CurrentFormExperienceGatingState.value === "NoGate";
  });

  // When form success or blind form
  const ungatedClass = computed(() => {
    if (CurrentFormExperienceGatingState.value === "NoGate") {
      return 'no-gate';
    }
    else if (FormState.value === "RegularFormComplete") {
      return 'ungated';
    }
    else if (FormState.value === "BlindForm") {
      return 'ungated blind';
    }
    else {
      return "";
    }
  });

  // Playing the video
  const videoPlaying = ref(false);
  const btnClick = ref(false);

  const ytPlayer = new window.YouTubePlayer('yt-iframe', {
    width: '420',
    height: '315',
    videoId: videoData.value.youTubeId
  });
  ytPlayer.events.onStateChange.subscribe("playing-class-handler", (event: { data: number }) => {
    var state = event.data;
    if (state === 0) {
      // Remove the playing class
      videoPlaying.value = false;
    } else {
      // Add the player class if video is playing
      videoPlaying.value = true;
    }
  });

  let blindFormSubmitted = false;
  const { submitBlindFormIfEnabled } = useBlindSubmit();
  watch(btnClick, () => {
    if (btnClick.value) {
      logger.debug('video btn clicked', btnClick.value);
      if (blindFormSubmitted === false) {
        blindFormSubmitted = true;
        submitBlindFormIfEnabled();
      }
      ytPlayer.playVideo();
      btnClick.value = false;
    }
  });

  // Removes the utm-url class if form success
  watch(() => showUnlockedView.value, (isUnlocked) => {
    if (isUnlocked) {
      afterCompleteHandler();
      if (FormState.value === "RegularFormComplete") {
        ytPlayer.playVideo();
      }
    }
  }, { immediate: true });

  let beforeUpdateLogged = false;
  onBeforeUpdate(() => {
    if (!beforeUpdateLogged) {
      beforeUpdateLogged = true;
      logger.timeEnd(`${props.formSettingsKey}-experienceRender`);
      logger.timeEnd(`${props.formSettingsKey}-from-init-to-first-render`);
      logger.timeEnd("initVideoFormExp");
    }
  });
</script>

<template>
  <div class="resource-landing-page resource-video eclipse-version" :class="[ungatedClass, { playing: videoPlaying }]">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <topContent :custom-data="data">
            <!-- Breadcrumbs -->
            <template #breadcrumb>
              <breadCrumbs :custom-data="data" />
            </template>
          </topContent>
        </div>

        <!-- The Main Video Content -->
        <videoContent :custom-data="data" :widget-data="widgetData">
          <template #no-gate-video>
            <noGateVideo @playclicked="$event => btnClick = $event" />
          </template>

          <template #resource-form>
            <div id="video-landing-page-form-wrapper" class="vid-form vid-space">
              <resourceForm :form-settings-key="formSettingsKey" :widget-data="widgetData" :disable-form-heading="true" :custom-data="customData" />
            </div>
          </template>
        </videoContent>

        <div class="row">
          <!-- About this Doc -->
          <div class="col-12 col-md-6 col-xl-5">
            <aboutVid v-if="!showUnlockedView" :custom-data="data" />
          </div>
          <!-- Key Takeaways -->
          <div class="col-12 col-md-6 col-xl-6 offset-xl-1">
            <keyTakeaways v-if="!showUnlockedView" :custom-data="data" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
