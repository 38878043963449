<script lang=ts setup>
  const props = defineProps<{
    customData?: Record<string, any>;
  }>();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const data = props.customData!;
</script>

<template>
  <div v-if="data.summary !== null" class="about">
    <h5>About This Video</h5>
    <p v-html="data.summary"></p>
  </div>
</template>